import Vue, { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import Carousel3d from 'vue3-carousel-3d';
import "vue3-carousel-3d/dist/index.css"

const app = createApp(App)

import { createMetaManager } from 'vue-meta'

app
	.use(Antd)
	.use(Carousel3d)
	.use(router)
	.use(createMetaManager())
	.mount('#app')
